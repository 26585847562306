#navbar {
  -webkit-transform: translateY(-150px);
  transform: translateY(-150px);
  -webkit-transition: -webkit-transform 400ms ease-in;
  transition: -webkit-transform 400ms ease-in;
  transition: transform 400ms ease-in;
  transition: transform 400ms ease-in, -webkit-transform 400ms ease-in;

  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
  background: #e9d9d4e1;
  height: 50px;
  border-bottom: 1px solid black;
}

#navbar.slideIn {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

#navbar.slideOut {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

#navbar ul {
  text-align: right;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 1.325rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 50px;
  margin-right: 9%;
}

#navbar ul li {
  display: inline;
  margin: 15px;
}

#navbar ul li a {
  color: rgb(110, 103, 102);
  text-decoration: none;
}

#navbar ul li a:hover {
  border-bottom: 2px solid rgb(110, 103, 102);
}

@media only screen and (max-width: 760px) {
  #navbar ul {
    text-align: center;
    font-size: 1.3rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 0px;
  }
}
