.contact-ftr {
  background: rgba(218, 216, 215, 0.938);
  padding: 0px 0px 20px 0px;
  width: 95vw;
  max-width: 1100px;
  height: 40vh;
  margin: 0 auto;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 40px 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.contact,
.connect {
  width: 50%;
}

.connect {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* border: 1px solid black; */
}

.connect-buttons {
  display: grid;
  grid-template-columns: auto auto;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.contact-info a,
.connect-buttons a {
  font-family: Roboto Condensed;
  font-size: 25px;
}
.contact-info a {
  margin-top: 10px;
  color: rgb(110, 103, 102);
  text-decoration: none;
  display: block;
  background: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.connect-buttons a {
  margin-top: 10px;
  color: rgb(110, 103, 102);
  text-decoration: none;
  border: 2px solid rgb(110, 103, 102);
  padding: 0px 5px;
  width: 90%;
  max-width: 250px;
  height: 88px;
  display: block;
  background: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.connect-buttons a:hover {
  color: #fff;
  background: rgb(109, 107, 106);
  border: 2px solid rgba(110, 103, 102, 0.623);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.contact-info a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.connect-buttons a:hover p {
  color: #fff;
}

.connect-buttons p {
  vertical-align: middle;
  position: relative;
  font-style: normal;
  font-weight: normal;
  top: 30%;
}

.connect-buttons a:hover .react-icons {
  color: #fff;
}
.contact-info p .react-icons {
  /* color: rgba(110, 103, 102, 0.801); */
  /* font-size: 25px; */
  /* margin-right: 10px; */
  /* min-height: 10px;
  min-width: 10px;
  display: block;
  border: 1px solid white; */
}
.email {
  color: rgba(110, 103, 102, 0.801);
  min-height: 10px;
  min-width: 10px;
  display: block;
}

.connect-buttons p .react-icons {
  color: rgba(110, 103, 102, 0.801);
  font-size: 25px;
  margin-left: 10px;
  display: block;
}

.contact p {
  font-family: Roboto Condensed;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  text-align: left;
}

.contact .contact-info {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  text-align: left;
}

.contact .contact-info h3 {
  font-size: 45px;
  margin-top: 5px;
  font-weight: bold;
  padding: 0px;
}

.contact .contact-info h4 {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  margin-top: 30px;
}

.connect-buttons a p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.signature {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  color: #916e6d;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-box-flex: 1;

  -ms-flex: 1 100%;

  flex: 1 100%;
  padding: 20px 25px;
}

@media only screen and (max-width: 600px) {
  .contact-ftr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -ms-flex-line-pack: left;
    align-content: left;
    max-width: 90vw;
  }

  .contact,
  .connect {
    width: 100%;
  }

  .contact .contact-info {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }
}

@media only screen and (max-width: 415px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 20px;
  }
}

@media only screen and (min-width: 601px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 20px;
  }
}

@media only screen and (min-width: 700px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 25px;
  }
}
