.outline-section {
  /* border: 2px solid #635F5F; */
  width: 90vw;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.outer-rectangle {
  background: rgba(218, 216, 215, 0.849);
  padding: 0px 0px 20px 0px;
  width: 100%;
  height: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-content: center; */
}

.inner-rectangle {
  background: #ffffff;
  margin: 0 auto;
  width: 100%;
  height: 90%;
}

h3 {
  font-family: Roboto Condensed;

  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  width: 95%;
  text-align: left;
  padding: 10px 10px;

  /*
align-self: flex-end;
justify-self: flex-end; */
}

#about,
#work {
  height: 70px;
}
