.aboutMe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 95%;
  min-height: 80vh;
  height: 100%;
  margin: 0 auto;
}

.about-name {
  width: 30%;
  margin-top: 5%;
  margin-left: 5%;
}
.about-description {
  width: 60%;
  margin-left: 50px;
  margin-top: 5%;
}

.about-description h4,
.about-name h4 {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 2.65rem;
  text-align: left;
  letter-spacing: -0.01em;
  color: #494646;
  color: #c59795;
}

.about-name .bio-paragraph {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.3rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-name ul {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.1rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
  margin-top: 22px;
}

.about-name ul br {
  display: none;
}

.about-description .bio-paragraph {
  font-family: Roboto Condensed;
  letter-spacing: 0px;
  font-size: 1.2rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-description .bio-tools {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.1rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-description .bio-tools p {
  margin-bottom: 3%;
}

.title {
  border-bottom: 1px solid rgba(196, 192, 190, 0.897);
  display: block;
  width: 75%;
}

.bio-tools strong {
  font-weight: bold;
}

.tl-blt {
  font-weight: bold;
  font-size: 1.5rem;
}

.bio-paragraph-about {
  border-bottom: 1px solid rgba(196, 192, 190, 0.897);
  display: block;
  width: 90%;
}

@media only screen and (max-width: 660px) {
  .aboutMe {
    width: 90%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about-name {
    width: 100%;
    margin-left: 0%;
  }

  .about-name ul li {
    display: inline;
    margin: 0px 10px;
    font-size: 1rem;
    font-weight: bold;
  }

  .about-description .bio-paragraph {
    margin-bottom: 20px;
    width: 100%;
    font-size: 1.1rem;
  }

  .about-description {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .about-name ul br {
    display: inline-block;
  }

  .tl-blt {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .about-description .bio-tools {
    font-family: Roboto Condensed;
    letter-spacing: 1px;
    font-size: 1.1rem;
    text-align: left;
    color: #494646;
  }

  .title {
    width: 100%;
  }
}
