.work-section {
  height: 100vh;
  margin-bottom: 30px;
}

.project {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.project-info {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.project-text {
  width: 100%;

  height: 70%;
}

.project-header {
  width: 100%;
}

.project-img {
  width: 50%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 20px;
}

.project-img img {
  height: 90%;
  width: 100%;
  max-width: 650px;
  -o-object-fit: contain;
  object-fit: contain;
}

.project-text {
  text-align: center;
}
.project button {
  background: #91867c;
  text-align: center;
  width: 30%;
  max-width: 150px;
  min-width: 85px;
  height: 42px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 3%;
  text-align: center;
}

.project button a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.project button:hover {
  background: #e9d9d4e1;
}

.project button:hover a {
  color: rgb(110, 103, 102);
  text-decoration: none;
  font-weight: bold;
}

.project-text p {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  font-size: 1rem;
  letter-spacing: 0.03em;
  text-align: left;
  color: #9d9391;
  color: #5a5252;
  padding: 10px 20px;
}

.project-header h4 {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  letter-spacing: 0.03em;
  color: #9d9391;
  text-align: left;
  padding: 10px 36px;
}

.github-link {
  display: block;
  text-align: left;
  text-decoration: none;
  padding-left: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgb(110, 103, 102);
  margin-top: 10px;
}

.github-link .react-icons {
  font-size: 23px;
  color: rgb(110, 103, 102);
  margin-left: 10px;
  vertical-align: middle;
  padding-bottom: 1px;
}

.github-link:hover {
  text-decoration: underline;
}

.tech-stack {
  display: block;
  margin-top: 10px;
}

.tech-stack strong {
  font-weight: bold;
}

.back-end {
  display: block;
  margin-top: 10px;
}

@media only screen and (max-width: 660px) {
  .project {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .project-info {
    width: 100%;
    height: 50%;
  }
  .project-header {
    width: 100%;
    margin-top: 15px;
  }

  .project-header h4 {
    font-size: 2.5rem;
  }

  .project-img {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    height: 50%;
  }

  .github-link {
    text-align: center;

    margin-top: 15px;
  }
}

@media only screen and (max-width: 660px) {
  .work-section {
    height: 100%;
    margin-bottom: 30px;
  }

  .project-img {
    padding: 10px;
    margin-top: 30px;
  }

  .project-img img {
    width: 70%;
  }
}

@media only screen and (min-width: 551px) {
  @media only screen and (max-width: 750px) {
    .project-header {
      width: 100%;
    }
    .project-header h4 {
      font-size: 2.5rem;
    }

    .project-text p {
      font-size: 15px;
    }
  }
}

@media only screen and (min-width: 850px) {
  .work-section {
    height: 90vh;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1100px) {
  .name-span {
    display: block;
  }
}
