#home {
  height: 100vh;
}

.second-navbar {
  position: relative;
  top: 5%;
}

.second-navbar ul {
  text-align: right;
  margin-right: 45px;

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 1.325rem;
}
.second-navbar ul li {
  display: inline;
  margin: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  padding: 7px;
}

.second-navbar ul li a {
  color: #635f5f;
  text-decoration: none;
}

.second-navbar ul li a:hover {
  border-bottom: 2px solid #9d9391;
}

.name-intro__layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.name-intro {
  background: #e9d9d4e3;
  border-radius: 100%;
  margin: 0 auto;
  width: 500px;
  height: 500px;

  position: relative;
  top: 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
header h1 {
  font-family: Petit Formal Script;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  text-align: center;
  letter-spacing: -0.07em;
  color: #ffffff;
  text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.42);
  /* transform: rotate(-8deg); */
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid black; */
}

header h2 {
  padding-top: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  line-height: 23px;
  font-size: 3rem;
  text-align: center;
  letter-spacing: -0.01em;
  color: #494646;
  margin-top: 50px;
}

.intro-section {
  height: 100vh;
}

@media only screen and (max-width: 520px) {
  @media screen and (min-width: 150px) {
    .name-intro__layout {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .name-intro {
      width: 300px;
      height: 300px;
    }

    header h1 {
      font-size: 3.5rem;
      text-align: center;
    }

    header h2 {
      padding-top: 10px;
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 420px) {
    .name-intro {
      top: 20%;
      width: 350px;
      height: 350px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .second-navbar ul {
    text-align: center;
    margin-right: 0px;
    font-size: 1.225rem;
  }

  .second-navbar ul li {
    margin: 8px;
    padding: 7px;
  }
}
