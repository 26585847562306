@import url(https://fonts.googleapis.com/css?family=Petit+Formal+Script);
@import url(https://use.typekit.net/dns6ten.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Roboto+Slab);
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-size: 16px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background: url(/static/media/background.64f8f395.jpg) no-repeat center left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

@media only screen and (max-width: 1000px) {
  body {
    max-width: 1000px;
  }
}

.outline-section {
  /* border: 2px solid #635F5F; */
  width: 90vw;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.outer-rectangle {
  background: rgba(218, 216, 215, 0.849);
  padding: 0px 0px 20px 0px;
  width: 100%;
  height: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-content: center; */
}

.inner-rectangle {
  background: #ffffff;
  margin: 0 auto;
  width: 100%;
  height: 90%;
}

h3 {
  font-family: Roboto Condensed;

  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  width: 95%;
  text-align: left;
  padding: 10px 10px;

  /*
align-self: flex-end;
justify-self: flex-end; */
}

#about,
#work {
  height: 70px;
}

#home {
  height: 100vh;
}

.second-navbar {
  position: relative;
  top: 5%;
}

.second-navbar ul {
  text-align: right;
  margin-right: 45px;

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 1.325rem;
}
.second-navbar ul li {
  display: inline;
  margin: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  padding: 7px;
}

.second-navbar ul li a {
  color: #635f5f;
  text-decoration: none;
}

.second-navbar ul li a:hover {
  border-bottom: 2px solid #9d9391;
}

.name-intro__layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.name-intro {
  background: #e9d9d4e3;
  border-radius: 100%;
  margin: 0 auto;
  width: 500px;
  height: 500px;

  position: relative;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
header h1 {
  font-family: Petit Formal Script;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  text-align: center;
  letter-spacing: -0.07em;
  color: #ffffff;
  text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.42);
  /* transform: rotate(-8deg); */
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid black; */
}

header h2 {
  padding-top: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  line-height: 23px;
  font-size: 3rem;
  text-align: center;
  letter-spacing: -0.01em;
  color: #494646;
  margin-top: 50px;
}

.intro-section {
  height: 100vh;
}

@media only screen and (max-width: 520px) {
  @media screen and (min-width: 150px) {
    .name-intro__layout {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .name-intro {
      width: 300px;
      height: 300px;
    }

    header h1 {
      font-size: 3.5rem;
      text-align: center;
    }

    header h2 {
      padding-top: 10px;
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 420px) {
    .name-intro {
      top: 20%;
      width: 350px;
      height: 350px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .second-navbar ul {
    text-align: center;
    margin-right: 0px;
    font-size: 1.225rem;
  }

  .second-navbar ul li {
    margin: 8px;
    padding: 7px;
  }
}

.aboutMe {
  display: flex;
  flex-direction: row;
  width: 95%;
  min-height: 80vh;
  height: 100%;
  margin: 0 auto;
}

.about-name {
  width: 30%;
  margin-top: 5%;
  margin-left: 5%;
}
.about-description {
  width: 60%;
  margin-left: 50px;
  margin-top: 5%;
}

.about-description h4,
.about-name h4 {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 2.65rem;
  text-align: left;
  letter-spacing: -0.01em;
  color: #494646;
  color: #c59795;
}

.about-name .bio-paragraph {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.3rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-name ul {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.1rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
  margin-top: 22px;
}

.about-name ul br {
  display: none;
}

.about-description .bio-paragraph {
  font-family: Roboto Condensed;
  letter-spacing: 0px;
  font-size: 1.2rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-description .bio-tools {
  font-family: Roboto Condensed;
  letter-spacing: 1px;
  font-size: 1.1rem;
  line-height: 2em;
  text-align: left;
  color: #494646;
}

.about-description .bio-tools p {
  margin-bottom: 3%;
}

.title {
  border-bottom: 1px solid rgba(196, 192, 190, 0.897);
  display: block;
  width: 75%;
}

.bio-tools strong {
  font-weight: bold;
}

.tl-blt {
  font-weight: bold;
  font-size: 1.5rem;
}

.bio-paragraph-about {
  border-bottom: 1px solid rgba(196, 192, 190, 0.897);
  display: block;
  width: 90%;
}

@media only screen and (max-width: 660px) {
  .aboutMe {
    width: 90%;
    height: 100%;
    flex-direction: column;
  }

  .about-name {
    width: 100%;
    margin-left: 0%;
  }

  .about-name ul li {
    display: inline;
    margin: 0px 10px;
    font-size: 1rem;
    font-weight: bold;
  }

  .about-description .bio-paragraph {
    margin-bottom: 20px;
    width: 100%;
    font-size: 1.1rem;
  }

  .about-description {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .about-name ul br {
    display: inline-block;
  }

  .tl-blt {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .about-description .bio-tools {
    font-family: Roboto Condensed;
    letter-spacing: 1px;
    font-size: 1.1rem;
    text-align: left;
    color: #494646;
  }

  .title {
    width: 100%;
  }
}

.work-section {
  height: 100vh;
  margin-bottom: 30px;
}

.project {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.project-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.project-text {
  width: 100%;

  height: 70%;
}

.project-header {
  width: 100%;
}

.project-img {
  width: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.project-img img {
  height: 90%;
  width: 100%;
  max-width: 650px;
  object-fit: contain;
}

.project-text {
  text-align: center;
}
.project button {
  background: #91867c;
  text-align: center;
  width: 30%;
  max-width: 150px;
  min-width: 85px;
  height: 42px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 3%;
  text-align: center;
}

.project button a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.project button:hover {
  background: #e9d9d4e1;
}

.project button:hover a {
  color: rgb(110, 103, 102);
  text-decoration: none;
  font-weight: bold;
}

.project-text p {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  font-size: 1rem;
  letter-spacing: 0.03em;
  text-align: left;
  color: #9d9391;
  color: #5a5252;
  padding: 10px 20px;
}

.project-header h4 {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  letter-spacing: 0.03em;
  color: #9d9391;
  text-align: left;
  padding: 10px 36px;
}

.github-link {
  display: block;
  text-align: left;
  text-decoration: none;
  padding-left: 15px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgb(110, 103, 102);
  margin-top: 10px;
}

.github-link .react-icons {
  font-size: 23px;
  color: rgb(110, 103, 102);
  margin-left: 10px;
  vertical-align: middle;
  padding-bottom: 1px;
}

.github-link:hover {
  text-decoration: underline;
}

.tech-stack {
  display: block;
  margin-top: 10px;
}

.tech-stack strong {
  font-weight: bold;
}

.back-end {
  display: block;
  margin-top: 10px;
}

@media only screen and (max-width: 660px) {
  .project {
    flex-direction: column;
  }

  .project-info {
    width: 100%;
    height: 50%;
  }
  .project-header {
    width: 100%;
    margin-top: 15px;
  }

  .project-header h4 {
    font-size: 2.5rem;
  }

  .project-img {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    height: 50%;
  }

  .github-link {
    text-align: center;

    margin-top: 15px;
  }
}

@media only screen and (max-width: 660px) {
  .work-section {
    height: 100%;
    margin-bottom: 30px;
  }

  .project-img {
    padding: 10px;
    margin-top: 30px;
  }

  .project-img img {
    width: 70%;
  }
}

@media only screen and (min-width: 551px) {
  @media only screen and (max-width: 750px) {
    .project-header {
      width: 100%;
    }
    .project-header h4 {
      font-size: 2.5rem;
    }

    .project-text p {
      font-size: 15px;
    }
  }
}

@media only screen and (min-width: 850px) {
  .work-section {
    height: 90vh;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1100px) {
  .name-span {
    display: block;
  }
}

#navbar {
  -webkit-transform: translateY(-150px);
  transform: translateY(-150px);
  -webkit-transition: -webkit-transform 400ms ease-in;
  transition: -webkit-transform 400ms ease-in;
  transition: transform 400ms ease-in;
  transition: transform 400ms ease-in, -webkit-transform 400ms ease-in;

  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
  background: #e9d9d4e1;
  height: 50px;
  border-bottom: 1px solid black;
}

#navbar.slideIn {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

#navbar.slideOut {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

#navbar ul {
  text-align: right;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 1.325rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  margin-right: 9%;
}

#navbar ul li {
  display: inline;
  margin: 15px;
}

#navbar ul li a {
  color: rgb(110, 103, 102);
  text-decoration: none;
}

#navbar ul li a:hover {
  border-bottom: 2px solid rgb(110, 103, 102);
}

@media only screen and (max-width: 760px) {
  #navbar ul {
    text-align: center;
    font-size: 1.3rem;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
}

.contact-ftr {
  background: rgba(218, 216, 215, 0.938);
  padding: 0px 0px 20px 0px;
  width: 95vw;
  max-width: 1100px;
  height: 40vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 40px 50px;
  box-sizing: border-box;

  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
}

.contact,
.connect {
  width: 50%;
}

.connect {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
}

.connect-buttons {
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.contact-info a,
.connect-buttons a {
  font-family: Roboto Condensed;
  font-size: 25px;
}
.contact-info a {
  margin-top: 10px;
  color: rgb(110, 103, 102);
  text-decoration: none;
  display: block;
  background: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.connect-buttons a {
  margin-top: 10px;
  color: rgb(110, 103, 102);
  text-decoration: none;
  border: 2px solid rgb(110, 103, 102);
  padding: 0px 5px;
  width: 90%;
  max-width: 250px;
  height: 88px;
  display: block;
  background: none;
  box-sizing: border-box;
}

.connect-buttons a:hover {
  color: #fff;
  background: rgb(109, 107, 106);
  border: 2px solid rgba(110, 103, 102, 0.623);
  box-sizing: border-box;
}

.contact-info a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.connect-buttons a:hover p {
  color: #fff;
}

.connect-buttons p {
  vertical-align: middle;
  position: relative;
  font-style: normal;
  font-weight: normal;
  top: 30%;
}

.connect-buttons a:hover .react-icons {
  color: #fff;
}
.contact-info p .react-icons {
  /* color: rgba(110, 103, 102, 0.801); */
  /* font-size: 25px; */
  /* margin-right: 10px; */
  /* min-height: 10px;
  min-width: 10px;
  display: block;
  border: 1px solid white; */
}
.email {
  color: rgba(110, 103, 102, 0.801);
  min-height: 10px;
  min-width: 10px;
  display: block;
}

.connect-buttons p .react-icons {
  color: rgba(110, 103, 102, 0.801);
  font-size: 25px;
  margin-left: 10px;
  display: block;
}

.contact p {
  font-family: Roboto Condensed;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  text-align: left;
}

.contact .contact-info {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  text-align: left;
}

.contact .contact-info h3 {
  font-size: 45px;
  margin-top: 5px;
  font-weight: bold;
  padding: 0px;
}

.contact .contact-info h4 {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0.03em;
  color: rgb(110, 103, 102);
  margin-top: 30px;
}

.connect-buttons a p {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.signature {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  color: #916e6d;
  text-align: right;
  box-sizing: border-box;

  flex: 1 1 100%;
  padding: 20px 25px;
}

@media only screen and (max-width: 600px) {
  .contact-ftr {
    flex-direction: column;
    height: 100%;
    align-items: left;
    justify-content: left;
    align-content: left;
    max-width: 90vw;
  }

  .contact,
  .connect {
    width: 100%;
  }

  .contact .contact-info {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }
}

@media only screen and (max-width: 415px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 20px;
  }
}

@media only screen and (min-width: 601px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 20px;
  }
}

@media only screen and (min-width: 700px) {
  .contact-info a,
  .connect-buttons a {
    font-family: Roboto Condensed;
    font-size: 25px;
  }
}

.spacer {
  height: 50vh;
}

